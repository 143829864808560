@media screen and (min-width: 1201px) {
  .Line {
    border-top: 0.16vh solid rgba(0, 0, 0, 0.6);
    height: 0.16vh;
    width: 90vw;
    margin: auto;
    animation: leftRight 12s normal;
    position: relative;
  }

  @keyframes leftRight {
    0% {
      opacity: 0;
      left: -100vw;
    }
    10% {
      opacity: 0;
      left: -100vw;
    }
    20%,
    100% {
      opacity: 1;
      left: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .Line {
    display: none;
  }
}
